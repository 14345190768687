import { Button, ButtonProps } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { StrapiButton } from '@src/types/strapi'

export interface ButtonCommonComponentProps
  extends StrapiButton,
    Omit<ButtonProps, 'title' | 'id' | 'size' | 'variant' | 'color'> {
  renderWithContainer?: boolean
}

const sizes: Record<ButtonCommonComponentProps['size'], any> = {
  xl: {
    width: { base: '17.3125rem', md: '23.8125rem' },
    height: { base: '3.125rem', md: '4.25rem' },
    fontSize: { base: '1.2rem', md: '1.375rem' },
    fontWeight: '600',
  },
  lg: {
    width: '18.75rem',
    height: '3.375rem',
    fontSize: '1rem',
    fontWeight: '600',
  },
  md: {
    width: '17.3125rem',
    height: '3.375rem',
  },
  sm: {
    width: '5.375rem',
    height: '2.375rem',
  },
}

const RenderIcon = (props: Pick<ButtonCommonComponentProps, 'icon'>) => {
  if (!props.icon) return null

  return <img src={props.icon.url} alt={props.icon.name} width={props.icon.width} height={props.icon.height} />
}

const RenderButton = (props: Omit<ButtonCommonComponentProps, 'renderWithContainer'>) => {
  const { size, variant, color, link, isExternal, title, icon, ...buttonProps } = props
  const { width, height, ...dynamicProps } = sizes[size]

  return (
    <Button
      variant={variant}
      colorScheme={color}
      as={'a'}
      leftIcon={<RenderIcon icon={icon} />}
      href={link}
      target={isExternal ? '_blank' : '_self'}
      rel={isExternal ? 'noopener noreferrer' : ''}
      width={{ base: '100%', md: width }}
      height={height}
      {...dynamicProps}
      {...buttonProps}
    >
      {title}
    </Button>
  )
}

export const ButtonCommonComponent = ({ renderWithContainer = true, ...props }: ButtonCommonComponentProps) => {
  if (renderWithContainer) {
    return (
      <Container>
        <RenderButton {...props} />
      </Container>
    )
  }

  return <RenderButton {...props} />
}
