import ModalAlert from '@components/ModalAlert'
import BlipChat from '@components/ThirdParty/BlipChat'
import { useLGPD } from '@gazin/react-gazin-lgpd'
import '@gazin/react-gazin-lgpd/src/styles.css'
import * as Sentry from '@sentry/nextjs'
import { ICookiesConsent } from '@src/interfaces/Home.interface'
import { configSentry } from '@src/sentry.client.config'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './CookiesConsent.module.scss'
export let IS_SENTRY_INITIALIZED = false

const CookiesConsent: React.FC<ICookiesConsent> = ({ content, privacyPolicyData, savaDatePrivacyPolicy }) => {
  const [blipChatAccepted] = useState<boolean>(false)
  const [sentryAccepted, setSentryAccepted] = useState<boolean>(false)
  const [showPrivacyPolicyData, setShowPrivacyPolicyData] = useState<boolean>(false)

  const config = useMemo(
    () => ({
      lang: 'pt',
      translations: {
        pt: {
          ok: 'Aceitar',
          privacyPolicyUrl: '/politica-de-privacidade',
          consentNotice: {
            description: content,
          },
          purposes: {
            analytics: {
              title: 'Analytics',
            },
            security: {
              title: 'Segurança',
            },
            livechat: {
              title: 'Chat ao vivo',
            },
            advertising: {
              title: 'Anúncios',
            },
            styling: {
              title: 'Aparência do site',
            },
          },
        },
      },
      services: [
        // {
        //   name: 'blip-chat',
        //   title: 'Blip Chat',
        //   onlyOnce: true,
        //   default: true,
        //   description:
        //     'Utilizamos o serviço da Blip para oferecer nossos produtos e informações através do chatbot dentro do nosso site.',
        //   purposes: ['livechat'],
        //   callback: consentState => {
        //     if (!consentState) return
        //     // eslint-disable-next-line no-console
        //     console.log('Blip Chat Initialized')
        //     setBlipChatAccepted(true)
        //   },
        // },
        {
          name: 'sentry',
          title: 'Sentry',
          onlyOnce: true,
          default: true,
          description:
            'Utilizamos um serviço para fazer o monitoramento de desempenho e estabilidade do site e fazer a rastreabilidade de erros.',
          purposes: ['performance'],
          callback: consentState => {
            if (!IS_SENTRY_INITIALIZED && consentState) {
              setSentryAccepted(consentState)
              IS_SENTRY_INITIALIZED = true
            }

            Sentry.init({ ...configSentry, enabled: IS_SENTRY_INITIALIZED })
          },
        },
      ],
    }),
    [content]
  )

  const { showConsentModal, initInstance } = useLGPD(config)

  useEffect(() => {
    initInstance()
  }, [])

  useEffect(() => {
    if (blipChatAccepted || sentryAccepted) {
      if (privacyPolicyData && privacyPolicyData.show) {
        if (privacyPolicyData?.modal?.title || privacyPolicyData?.modal?.description) {
          setShowPrivacyPolicyData(true)
        }
      } else {
        setShowPrivacyPolicyData(false)
        savaDatePrivacyPolicy()
      }
    }
  }, [blipChatAccepted, sentryAccepted, privacyPolicyData.show])

  const onHandleButtonPrivacyPolicy = () => {
    setShowPrivacyPolicyData(false)
    savaDatePrivacyPolicy()
  }

  return (
    <>
      <div className={styles.cookiesFooter}>
        {blipChatAccepted && <BlipChat />}
        <button onClick={showConsentModal}>Gerenciar cookies</button>
      </div>
      {showPrivacyPolicyData && (
        <ModalAlert {...privacyPolicyData.modal} onHandleButton={onHandleButtonPrivacyPolicy} />
      )}
    </>
  )
}

export default CookiesConsent
