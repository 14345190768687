import { Icon, IconProps } from '@chakra-ui/react'

export const TelephoneIcon = (props: IconProps) => {
  return (
    <Icon width="17" height="18" viewBox="0 0 17 18" fill="none" {...(props as any)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5771 13.7265C14.0755 14.1416 14.0756 14.8086 13.5709 15.2213L12.346 16.2231C11.8442 16.6335 10.9335 16.8239 10.3227 16.6147C10.3227 16.6147 7.85476 16.1495 4.79995 13.096C1.74515 10.0425 1.27974 7.57564 1.27974 7.57564C1.08554 6.9603 1.25863 6.05766 1.67153 5.55323L2.67373 4.32886C3.08431 3.82725 3.7535 3.82407 4.16921 4.3227L5.68193 6.13717C5.95853 6.46894 5.99006 7.02681 5.74871 7.38868L5.07651 8.39654C5.07651 8.39654 5.08465 10.0633 6.45932 11.4374C7.83398 12.8114 9.50148 12.8196 9.50148 12.8196L10.5098 12.1477C10.8694 11.9081 11.4324 11.94 11.7618 12.2144L13.5771 13.7265Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2002 9.20001C16.2002 5.05798 12.8423 1.7002 8.7002 1.7002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8252 9.1996C12.8252 6.92149 10.9784 5.07471 8.7002 5.07471"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
