import { Icon, IconProps } from '@chakra-ui/react'

export const ArrowLeftIcon = (props: IconProps) => {
  return (
    <Icon width="14px" height="15px" viewBox="0 0 14 15" fill="none" {...(props as any)}>
      <path
        d="M12.8713 7.24978L1.24097 7.24978"
        stroke="#0D71F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05613 1.42161L1.24097 7.24977L7.05613 13.0779"
        stroke="#0D71F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
