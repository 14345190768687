import { Icon, IconProps } from '@chakra-ui/react'

export const PlusIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 17 17" fill="none" {...(props as any)}>
      <path
        d="M8.5 5.16675L8.5 11.8334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8335 8.50012H5.16683"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
