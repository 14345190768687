import { Flex, Heading, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { ButtonCommonComponent } from '@components/Common/Button'
import { Video } from '@components/Common/Video'
import { Container } from '@components/Container'
import { StrapiYoutubeSection } from '@src/types/strapi/components/youtube-section'

export const YoutubeSection = (props: StrapiYoutubeSection) => {
  return (
    <Container>
      <VStack width="100%" gridGap={{ base: '1rem', md: '2.5rem' }}>
        <VStack gridGap={{ base: '0.8rem', md: '32px' }}>
          <Heading
            as="h2"
            fontWeight={{ base: '600', md: '700' }}
            fontSize={{ base: '1.5rem', md: '2rem' }}
            lineHeight="39px"
            textAlign="center"
          >
            {props.title}
          </Heading>
          <Text
            marginTop="0px !important"
            fontWeight="400"
            fontSize={{ base: '1rem', md: '1.5rem' }}
            textAlign="center"
            maxWidth="1132px"
          >
            {props.description}
          </Text>
        </VStack>
        <Wrap justify={'center'} flexDirection={{ base: 'column', md: 'row' }} spacing={{ base: '2rem', md: '1.5rem' }}>
          {props.videos.map(video => (
            <WrapItem key={video.id} maxWidth={'378px'}>
              <Video key={video.id} {...video} />
            </WrapItem>
          ))}
        </Wrap>
        <Text marginTop="0px !important" fontWeight="400" fontSize={{ base: '1rem', md: '1.5rem' }} textAlign="center">
          {props.subtitle}
        </Text>
        <Flex
          width="100%"
          justify="center"
          direction={{ base: 'column', md: 'row' }}
          gridGap={{ base: '1rem', md: '1.5rem' }}
          marginTop="0px !important"
        >
          {props.buttons.map(button => {
            return <ButtonCommonComponent key={button.id} {...button} renderWithContainer={false} />
          })}
        </Flex>
      </VStack>
    </Container>
  )
}
