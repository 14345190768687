import { ChakraProps, Icon } from '@chakra-ui/react'

export const MagnifyingGlassIcon = (props: Omit<React.SVGAttributes<SVGElement>, keyof ChakraProps>) => {
  return (
    <Icon viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M15.9791 15.7175L11.8005 11.5389M13.1933 7.0122C13.1933 10.2815 10.543 12.9319 7.27368 12.9319C4.00433 12.9319 1.354 10.2815 1.354 7.0122C1.354 3.74286 4.00433 1.09253 7.27368 1.09253C10.543 1.09253 13.1933 3.74286 13.1933 7.0122Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
