import { Icon, IconProps } from '@chakra-ui/react'

export const ArrowRightIcon = (props: IconProps) => {
  return (
    <Icon width="14px" height="15px" viewBox="0 0 14 15" fill="none" {...(props as any)}>
      <path
        d="M1.13213 7.24976L12.7625 7.24976"
        stroke="#0D71F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.94729 13.0779L12.7625 7.24977L6.94729 1.4216"
        stroke="#0D71F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
