import { Box, Text, VStack } from '@chakra-ui/react'
import { StrapiVideo } from '@src/types/strapi'
import dynamic from 'next/dynamic'
import styles from './Video.module.scss'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

export const Video = (props: StrapiVideo) => {
  return (
    <VStack gridGap={{ base: '0', md: '1rem' }} alignItems="flex-start">
      <Box
        width="100%"
        minWidth={{ base: '100%', md: '378px' }}
        maxHeight={'223px'}
        height={{ base: '200px', md: '223px' }}
        borderRadius="4px"
        className={styles.video}
      >
        <ReactPlayer
          url={props.url}
          width="100%"
          height="100%"
          style={{
            borderRadius: '4px',
          }}
        />
      </Box>
      <Text
        width="full"
        fontWeight={{ base: '600', md: '700' }}
        fontSize={{ base: '1rem', md: '1.3125rem' }}
        marginTop="0px !important"
        maxWidth={{ base: '100%', lg: '284px' }}
        lineHeight={'25.41px'}
        fontFamily={'Inter'}
      >
        {props.title}
      </Text>
      {props.watchNow && (
        <Text as="a" href={props.url} color="blue.500" target="__blank" width="full" marginTop="0px !important">
          Assista agora
        </Text>
      )}
    </VStack>
  )
}
