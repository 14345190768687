import { getExternalLinkProps, ref } from '@components/GenerateLink'
import { IMenuData, IPage } from '@src/interfaces/Home.interface'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo, useCallback, useEffect, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import styles from './Header.module.scss'

const DRAWER_ANIMATION_DELAY = 300

const RenderMenuItem = ({
  __component,
  id,
  external,
  url,
  label,
  page,
  renderButton,
  type,
}: IPage & { renderButton?: boolean }) => {
  const { query, pathname } = useRouter()

  let currentPageKey = 'home'

  if (Array.isArray(query.slug) && query.slug.length) {
    currentPageKey = query.slug[0]
  }

  if (pathname === '/produtos/[slug]') {
    currentPageKey = 'produtos'
  }

  const hRef = ref(type, url, page, null, external)

  return (
    <Link href={hRef} key={`${__component}-${id}`} passHref>
      <a
        {...getExternalLinkProps(type, external)}
        className={
          renderButton
            ? styles.copyStyleButtonMenu
            : cn(styles.item, { [styles.itemActive]: currentPageKey === page?.key })
        }
      >
        {label}
      </a>
    </Link>
  )
}

const RenderMenuItemMobile = ({ __component, id, external, url, page, label, type }: IPage) => {
  const { query } = useRouter()
  const currentPageKey = query.slug?.length ? query.slug[0] : null

  const file = null

  const hRef = ref(type, url, page, file, external)

  return (
    <Link key={`${__component}-${id}`} href={hRef} passHref>
      <a {...getExternalLinkProps('', external)}>
        <div className={cn(styles.itemMobile, { [styles.itemMobileActive]: currentPageKey === page?.key })}>
          {label}
        </div>
      </a>
    </Link>
  )
}

const Header: React.FC<IMenuData> = ({ logo, items: menuItems }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const { query } = useRouter()
  const size = useWindowSize()

  const [menuBackgroundVisibe, setMenuBackgroundVisible] = useState(false)

  const handleOpenMenu = useCallback(() => {
    setMenuOpened(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setMenuOpened(false)
  }, [])

  useEffect(() => {
    if (menuOpened) {
      setMenuBackgroundVisible(true)
    } else {
      setTimeout(() => {
        setMenuBackgroundVisible(false)
      }, DRAWER_ANIMATION_DELAY)
    }
  }, [menuOpened])

  useEffect(() => {
    const windowWidth = size.width || 0
    if (windowWidth > 1200 && menuOpened) {
      setMenuOpened(false)
    }
  }, [size.width, menuOpened])

  useEffect(() => {
    setMenuOpened(false)
  }, [query.slug])

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.menuItems}>
          <Link href="/">
            <a>
              <Image src={logo?.url} width="157.98px" height="40px" quality={100} />
            </a>
          </Link>
          {menuItems.map(item => (
            <RenderMenuItem key={item.id} {...item} />
          ))}
        </div>
      </div>
      <div className={styles.contentMobile}>
        <Link href="/" passHref>
          <a>
            <Image src={logo?.url} width={164} height={43} quality={90} />
          </a>
        </Link>

        <img className={styles.menuOpener} data-testid="menu-open" src="/menu.svg" onClick={handleOpenMenu} />
      </div>
      <div
        className={cn({
          [styles.drawerBackground]: menuOpened,
          [styles.drawerBackgroundVisible]: menuBackgroundVisibe,
        })}
        onClick={handleCloseMenu}
      >
        <div className={cn(styles.drawer, { [styles.drawerOpened]: menuOpened })}>
          <div className={styles.drawerContent}>
            <Image src={logo?.url} width={161} height={43} quality={90} />
            <img className={styles.menuOpener} data-testid="menu-close" src="/close.svg" onClick={handleCloseMenu} />
          </div>
          <div className={styles.menuItemsMobile}>
            {menuItems.map(item => (
              <RenderMenuItemMobile key={item.id} {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
