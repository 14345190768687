import { Flex, FlexProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export const Container = (props: PropsWithChildren<FlexProps>) => {
  return (
    <Flex width={'full'} justify="center" direction="row" align="center" data-testid="container">
      <Flex
        flex={1}
        justify="center"
        align="center"
        maxWidth={'1366px'}
        width={'100%'}
        paddingX={{ base: '1.8125rem', lg: '0' }}
        {...props}
        data-testid="container-children"
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
