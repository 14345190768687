import { Icon, IconProps } from '@chakra-ui/react'

export const PinPointIcon = (props: IconProps) => {
  return (
    <Icon viewBox="5 5 21 21" fill="none" {...(props as any)}>
      <path
        d="M13.116 18.187C9.562 18.655 7 19.96 7 21.5C7 23.433 11.029 25 16 25C20.971 25 25 23.433 25 21.5C25 19.96 22.438 18.655 18.884 18.187"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 11.833C22 8.612 19.314 6 16 6C12.686 6 10 8.612 10 11.833C10 16.208 16 21 16 21C16 21 22 16.208 22 11.833Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4142 10.5858C18.1953 11.3668 18.1953 12.6332 17.4142 13.4142C16.6332 14.1953 15.3668 14.1953 14.5858 13.4142C13.8047 12.6332 13.8047 11.3668 14.5858 10.5858C15.3668 9.80474 16.6332 9.80474 17.4142 10.5858"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
