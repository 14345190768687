import { IconProps } from '@chakra-ui/core'
import { Icon } from '@chakra-ui/react'

export const ArrowLeftSimpleIcon = (props: IconProps) => {
  return (
    <Icon width="8px" height="14px" viewBox="0 0 8 14" fill="none" {...(props as any)}>
      <path d="M7 13L1 7L7 1" stroke="#0D71F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  )
}
